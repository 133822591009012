import { graphql, useStaticQuery } from "gatsby"
// import Switch from 'react-switch';
import Switch from "rc-switch"

import "rc-switch/assets/index.css"
import * as React from "react"
import { useEffect, useState } from "react"
import { Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import "../../styles/_topbar.css"

import {
  Spacer,
  InvisibleOnMobile,
  InvisibleOnDesktop,
} from "../../../src/components/common/common.style"
import {
  BigLink,
  Col,
  Container,

  // Row,
  SmallLink,
} from "./../layout.style"
import {
  FullScreen,
  InnerContainer,
  MenuItem,
  MenuItemIcon,
  MenuItemExercise,
  MenuItemText,
  MenuItemLang,
  MobileItemLang,
  MenuItemLangBtn,
  MenuItemLangTos,
  TopBarContainer,
  MenuItemWorkout,
  MenuItemArticle,
} from "./topBar.style"
const isBrowser = typeof window !== "undefined"

const TopBar = ({ visibilitySensor, isTos }) => {
  const [modal, setModal] = useState(false)
  const [langSwitch, setLangSwitch] = useState(true)
  const { t, i18n } = useTranslation()
  const [showLangChange, setShowLangChange] = useState(true)

  const toggle = () => setModal(!modal)
  const images = useStaticQuery(graphql`
    query logo {
      logo: file(relativePath: { eq: "tobsosLogoFinal.png" }) {
        id
        extension
        publicURL
      }
      menu: file(relativePath: { eq: "menu.svg" }) {
        id
        extension
        publicURL
      }
      close: file(relativePath: { eq: "x.svg" }) {
        id
        extension
        publicURL
      }
      en: file(relativePath: { eq: "en2.png" }) {
        id
        extension
        publicURL
      }
      ro: file(relativePath: { eq: "ro2.png" }) {
        id
        extension
        publicURL
      }
    }
  `)

  useEffect(() => {
    if (
      window &&
      window.localStorage.getItem("lang") !== undefined &&
      window &&
      window.localStorage.getItem("lang") !== null
    ) {
      if (isBrowser && window && window.localStorage.getItem("lang") === "en") {
        setLangSwitch(false)
        i18n.changeLanguage("en")
      } else {
        setLangSwitch(true)
        try {
          i18n.changeLanguage("ro")
        } catch (error) {
          console.error(error)
        }
      }
    } else {
      isBrowser && window && window.localStorage.setItem("lang", "en")
      setLangSwitch(false)
      i18n.changeLanguage("en")
    }
    if (window) {
      setShowLangChange(true)
      if (
        window.location.pathname.includes("/article/") &&
        window.location.pathname.length > 9
      ) {
        setShowLangChange(false)
      }

      if (
        window.location.pathname.includes("/workout/") &&
        window.location.pathname.length > 9
      ) {
        setShowLangChange(false)
      }
    }
  }, [])

  const handleScroll = elementId => {
    setModal(false)
    if (
      window.location.pathname.includes("exercise") ||
      window.location.pathname.includes("article") ||
      window.location.pathname.includes("workout")
    ) {
      window.localStorage.setItem("scrollToMainPageId", elementId)
      window.location.href = "/"
    } else {
      if (elementId === "book-section") {
        window.scrollTo({ top: 0, behavior: "smooth" })
      } else {
        const element = document.getElementById(elementId)
        const elementPosition = element.getBoundingClientRect().top
        const offsetPosition = elementPosition - 45
        window.scrollBy({
          top: offsetPosition,
          behavior: "smooth",
        })
      }
    }
  }

  useEffect(() => {
    if (window) {
      if (window.localStorage.getItem("scrollToMainPageId")) {
        if (window.location.pathname === "/") {
          const elementId = window.localStorage.getItem("scrollToMainPageId")
          if (elementId === "book-section") {
            const element = document.getElementById(elementId)
            const elementPosition = element.getBoundingClientRect().top
            window.scrollBy({
              top: elementPosition,
              behavior: "smooth",
            })
          } else {
            const element = document.getElementById(elementId)
            const elementPosition = element.getBoundingClientRect().top
            const offsetPosition = elementPosition - 45
            window.scrollBy({
              top: offsetPosition,
              behavior: "smooth",
            })
          }
          window.localStorage.removeItem("scrollToMainPageId")
        }
      }
    }
  }, [])

  const changeLang = nextChecked => {
    setLangSwitch(nextChecked)

    if (nextChecked) {
      window &&
        window.localStorage.setItem("lang", "ro") &&
        i18n.changeLanguage("ro")
      i18n.changeLanguage("ro")
    } else {
      window &&
        window.localStorage.setItem("lang", "en") &&
        i18n.changeLanguage("en")
      i18n.changeLanguage("en")
    }
  }

  if (!isTos)
    return (
      <TopBarContainer>
        <InnerContainer>
          <a href="/">
            <img
              src={images.logo.publicURL}
              alt="Logo Tobsos"
              style={{ width: "10rem", marginTop: "2rem", marginRight: "4rem" }}
            />
          </a>

          <InvisibleOnDesktop>
            <MenuItemIcon onClick={() => toggle()}>
              <img
                src={images.menu.publicURL}
                alt="search icon"
                style={{ width: "4rem", marginTop: "1rem" }}
              />
            </MenuItemIcon>
          </InvisibleOnDesktop>

          <MenuItem
            onClick={() => handleScroll("book-section")}
            active={visibilitySensor && visibilitySensor.book}
          >
            <MenuItemText>{t("tobBarComponent.book")}</MenuItemText>
          </MenuItem>
          <MenuItem
            onClick={() => handleScroll("where-to-section")}
            active={visibilitySensor && visibilitySensor.buy}
          >
            <MenuItemText>{t("tobBarComponent.whereToBuy")}</MenuItemText>
          </MenuItem>
          <MenuItem
            onClick={() => handleScroll("bonuses-section")}
            active={visibilitySensor && visibilitySensor.bonus}
          >
            <MenuItemText>{t("tobBarComponent.bonuses")}</MenuItemText>
          </MenuItem>
          <MenuItem
            onClick={() => handleScroll("find-section")}
            active={visibilitySensor && visibilitySensor.find}
          >
            <MenuItemText>{t("tobBarComponent.findYourExercise")}</MenuItemText>
          </MenuItem>
          <MenuItemExercise
            onClick={() => (window.location.href = "/exercise")}
            active={
              typeof window !== "undefined"
                ? window.location.pathname.includes("exercise")
                : false
            }
          >
            <MenuItemText>{t("tobBarComponent.exercises")}</MenuItemText>
          </MenuItemExercise>
          <MenuItemArticle
            onClick={() => (window.location.href = "/article")}
            active={
              typeof window !== "undefined"
                ? window.location.pathname.includes("article")
                : false
            }
          >
            <MenuItemText>{t("tobBarComponent.articles")}</MenuItemText>
          </MenuItemArticle>
          <MenuItemWorkout
            onClick={() => (window.location.href = "/workout")}
            active={
              typeof window !== "undefined"
                ? window.location.pathname.includes("workout")
                : false
            }
          >
            <MenuItemText>{t("tobBarComponent.workouts")}</MenuItemText>
          </MenuItemWorkout>

          {showLangChange && (
            <MenuItemLang>
              <Switch
                checked={langSwitch}
                onChange={changeLang}
                onClick={changeLang}
                checkedChildren="RO"
                unCheckedChildren="EN"
                className={"hackSwitch"}
              ></Switch>
            </MenuItemLang>
          )}
        </InnerContainer>
        <FullScreen toggle={modal}>
          <Row
            style={{
              margin: 0,
            }}
          >
            <InnerContainer
              spaceBetween
              style={{
                paddingLeft: "5rem",
                paddingRight: "5rem",
                maxHeight: "9vh",
              }}
            >
              <a href="/">
                <img
                  src={images.logo.publicURL}
                  alt="Logo Tobsos"
                  style={{
                    width: "10rem",
                    marginTop: "2rem",
                    marginRight: "4rem",
                  }}
                />
              </a>

              <MenuItemIcon onClick={() => toggle()}>
                <img
                  src={images.close.publicURL}
                  alt="search icon"
                  style={{ width: "4rem", marginTop: "1rem" }}
                />
              </MenuItemIcon>
            </InnerContainer>
          </Row>
          <Row style={{ height: "100%" }}>
            <Container>
              <Row
                style={{
                  margin: 0,
                }}
              >
                <Col
                  lg={{ size: 6, offset: 3 }}
                  md={{ size: 6, offset: 3 }}
                  sm={{ size: 12, offset: 0 }}
                  xs={{ size: 12, offset: 0 }}
                >
                  <Spacer height={10} tHeight={10} mHeight={11} />
                  <BigLink onClick={() => handleScroll("book-section")}>
                    {t("tobBarComponent.book")}
                  </BigLink>
                  <BigLink onClick={() => handleScroll("where-to-section")}>
                    {t("tobBarComponent.whereToBuy")}
                  </BigLink>
                  <BigLink onClick={() => handleScroll("bonuses-section")}>
                    {t("tobBarComponent.bonuses")}
                  </BigLink>
                  <BigLink onClick={() => handleScroll("find-section")}>
                    {t("tobBarComponent.findYourExercise")}
                  </BigLink>
                  <Spacer height={4} />
                  <SmallLink
                    onClick={() => {
                      window.location.href = "/exercise"
                      setModal(false)
                    }}
                  >
                    {t("tobBarComponent.exercises")}
                  </SmallLink>
                  <SmallLink
                    onClick={() => {
                      window.location.href = "/article"
                      setModal(false)
                    }}
                  >
                    {t("tobBarComponent.articles")}
                  </SmallLink>
                  <SmallLink
                    onClick={() => {
                      window.location.href = "/workout"
                      setModal(false)
                    }}
                  >
                    {t("tobBarComponent.workouts")}
                  </SmallLink>

                  {showLangChange && (
                    <MobileItemLang>
                      <Switch
                        checked={langSwitch}
                        onChange={changeLang}
                        onClick={changeLang}
                        checkedChildren="RO"
                        unCheckedChildren="EN"
                        className={"hackSwitch"}
                      ></Switch>
                    </MobileItemLang>
                  )}
                </Col>
              </Row>
            </Container>
          </Row>
        </FullScreen>
      </TopBarContainer>
    )
  else {
    return (
      <TopBarContainer>
        <InnerContainer>
          <a href="/">
            <img
              src={images.logo.publicURL}
              alt="Logo Tobsos"
              style={{ width: "10rem", marginTop: "2rem", marginRight: "4rem" }}
            />
          </a>
          <h2
            style={{
              marginTop: "10px",
              marginLeft: "auto",
              marginRight: "30px",
              fontSize: "2rem",
            }}
          >
            {t("tosComponent.title")}
          </h2>
          <MenuItemLangTos>
            <Switch
              checked={langSwitch}
              onChange={changeLang}
              onClick={changeLang}
              checkedChildren="RO"
              unCheckedChildren="EN"
              className={"hackSwitch"}
            ></Switch>
          </MenuItemLangTos>
        </InnerContainer>
      </TopBarContainer>
    )
  }
}

export default TopBar
