import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "../../consts/translations/translationsEN.json";
import translationRO from "../../consts/translations/translationsRO.json";

const resources = {
  en: {
    translation: translationEN,
  },
  ro: {
    translation: translationRO,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",

  // keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
