import styled from 'styled-components';
import { colors } from '../../consts/colors';

export const TopBarContainer = styled.div`
  position: fixed;
  width: 100vw;
  z-index: 999;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5rem;
  padding-right: 5rem;
  height: 9vh;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  /* ${({ spaceBetween }) => spaceBetween && `justify-content:space-between;`};
  @media only screen and (max-width: 1366px) {
    justify-content: space-between;
  } */

  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;
export const FullScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  display: block;
  z-index: 99;
  ${({ toggle }) =>
    !toggle &&
    `display: none;   & * {
    overflow: hidden;
  }`};
`;

export const MenuItem = styled.div`
  height: 55px;
  margin: auto 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${({ color }) => (color ? color : colors.jetblack)};
  ${({ active }) =>
    active && `border-bottom: 3px solid ${colors.freespeechred};`};

  &:hover {
    color: ${({ color }) => (color ? color : colors.freespeechred)};
  }
  @media (max-width: 768px) {
    display: none;
  }

  @media (width: 768px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 960px) {
    margin: auto 0.4rem;
  }
  @media (min-width: 960px) and (max-width: 1240px) {
    margin: auto 0.6rem;
  }
`;
export const MenuItemIcon = styled.div`
  height: 55px;
  margin: auto 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const MenuItemExercise = styled.div`
  height: 35px;
  margin: auto 1rem;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  justify-self: end;
  color: ${({ color }) => (color ? color : colors.jetblack)};
  border: 2px solid ${colors.jetblack};
  border-radius: 3rem;
  ${({ active }) =>
    active &&
    `border-color: ${colors.freespeechred};
    color:white;
    background-color: ${colors.freespeechred}
    `};

  &:hover {
    color: ${({ color, active }) =>
      color || active ? color : colors.freespeechred};
  }
  @media (max-width: 768px) {
    display: none;
  }
  @media (width: 768px) {
    display: flex;
  }
`;

export const MenuItemArticle = styled.div`
  height: 35px;
  margin: auto 1rem;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  justify-self: end;
  color: ${({ color }) => (color ? color : colors.jetblack)};
  border: 2px solid ${colors.jetblack};
  border-radius: 3rem;
  ${({ active }) =>
    active &&
    `border-color: ${colors.freespeechred};
    color:white;
    background-color: ${colors.freespeechred}
    `};

  &:hover {
    color: ${({ color, active }) =>
      color || active ? color : colors.freespeechred};
  }
  @media (max-width: 768px) {
    display: none;
  }
  @media (width: 768px) {
    display: flex;
  }
`;

export const MenuItemWorkout = styled.div`
  height: 35px;
  margin: auto 1rem;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  justify-self: end;
  color: ${({ color }) => (color ? color : colors.jetblack)};
  border: 2px solid ${colors.jetblack};
  border-radius: 3rem;
  ${({ active }) =>
    active &&
    `border-color: ${colors.freespeechred};
    color:white;
    background-color: ${colors.freespeechred}
    `};

  &:hover {
    color: ${({ color, active }) =>
      color || active ? color : colors.freespeechred};
  }
  @media (max-width: 768px) {
    display: none;
  }
  @media (width: 768px) {
    display: flex;
  }
`;

export const MenuItemText = styled.li`
  margin: 0px;
  padding: 0.5rem;
  list-style: none;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  text-align: center;

  @media (min-width: 768px) and (max-width: 960px) {
    font-size: 12px;
  }
  @media (min-width: 960px) and (max-width: 1240px) {
    font-size: 13px;
  }
`;

export const MenuItemLang = styled.div`
  width: 70px;
  height: 38px;
  margin-left: auto;
  @media (max-width: 768px) {
    display: none;
  }
  @media (width: 768px) {
    display: flex;
  }
`;

export const MenuItemLangTos = styled.div`
  width: 70px;
  height: 38px;
  margin-left: auto;
  @media (width: 768px) {
    display: flex;
  }
`;

export const MobileItemLang = styled.div`
  display: inline-flex;
  width: 75px;
  height: 28px;
`;
