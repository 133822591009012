import Link from "gatsby-plugin-transition-link"
import styled, { css, keyframes } from "styled-components"
import { colors } from "../../consts/colors"

import { Container as BTContainer } from "reactstrap"

/*
BEAKPOINTS
mobile - < 768
tablet - 768 >= 1179
small desktop - <= 1920
large desktop - >= 1921
*/

export const InvisibleOnMobile = styled.div`
  @media (max-width: 768px) {
    display: none;
  }

  @media (width: 768px) {
    display: block;
  }
`
export const InvisibleOnDesktop = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
  @media (width: 767px) {
    display: none;
  }
`

export const BootstrapContainer = styled(BTContainer)`
  max-width: 160rem;
  padding-left: 0;
  padding-right: 0;
`

export const Container = styled.div`
  width: 100%;
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  /* ${({ semiFluid }) => semiFluid && `max-width: 86vw !important`}; */
  ${({ fluid }) =>
    `
        ${fluid ? `max-width: 100%` : `max-width: 120rem`};
        @media (max-width: 1199px) {
          ${fluid ? `max-width: 100%` : `max-width: 64rem`};
        }
        @media (max-width: 767px) {
          ${fluid ? `max-width: 100%` : `max-width: 33.5rem`};
        }
        @media (min-width: 1200) {
          max-width: 100%;
        }
    `}
`

export const Row = styled.div`
  width: calc(100% + 2rem);
  justify-content: center;
  display: flex;
  /* justify-content: center; */
  align-content: stretch;
  position: relative;
  flex-wrap: wrap;
  @media (max-width: 1199px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    ${({ reverse }) => reverse && `flex-dircetion: column-reverse`};
  }
  ${({ alignItems }) => alignItems && `align-items:${alignItems}`}
  ${({ spaceBetwen }) => spaceBetwen && `justify-content: space-between`};
  ${({ center }) => center && `justify-content: center`};
  ${({ header }) =>
    header &&
    `
    flex-direction: row;
    @media (max-width: 1199px) {
      flex-direction: row;
    }
    @media (max-width: 767px) {
      flex-direction: row;
    }
  `};
  ${({ tablet }) =>
    tablet &&
    `@media (max-width: 1199px) {flex-wrap: nowrap; flex-direction: column}`};
`

export const Col = styled.div`
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  ${({ lg }) =>
    lg &&
    `
        position: relative;
        @media (min-width: 1201px) {
            width: ${lg.size * 8 + (lg.size - 1) * 2}rem;
            ${
              lg.offset
                ? `margin-left: ${lg.offset * 8 + lg.offset * 2}rem`
                : "margin-left: 0"
            };
        }
      `}
  ${({ md }) =>
    md &&
    `
        position: relative;
        @media (max-width: 1200px) {
            width: ${md.size * 14 + (md.size - 1) * 2}rem;
            ${
              md.offset
                ? `margin-left: ${md.offset * 14 + md.offset * 2}rem`
                : "margin-left: 0"
            };
        }
      `}
  ${({ sm }) =>
    sm &&
    `
        position: relative;
        @media (max-width: 767px) {
            width: 100%;
        }
      `}
  ${({ half }) => half && `width: 50%`};
  ${({ align }) => align && `text-align: ${align}`};
  ${({ thidden }) => thidden && `@media(max-width: 1199px){display: none;}`};
  ${({ mhidden }) => mhidden && `@media(max-width: 767px){display: none;}`};
`

// typography elements start
export const H1 = styled.h1`
  color: ${({ color }) => (color ? color : colors.russianblack)};
`

export const H2 = styled.h2`
  font-size: 3.6rem;
  font-family: "Poppins", serif;

  color: ${({ color }) => (color ? color : colors.russianblack)};
`

export const H3 = styled.h3`
  font-family: "Poppins", serif;
  font-weight: 500;
  color: ${({ color }) => (color ? color : colors.jetblack)};
  font-size: 3.6rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  ${({ align }) => align && `text-align: ${align}`};
  @media (max-width: 1199px) {
    font-size: 3.6rem;
    ${({ talign }) => talign && `text-align: ${talign}`};
  }
  @media (max-width: 767px) {
    font-size: 3.4rem;
    ${({ malign }) => malign && `text-align: ${malign}`};
  }
`

export const H4 = styled.h4`
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: ${({ color }) => (color ? color : colors.jetblack)};
  font-size: 2.4rem;
  line-height: 1.4;
  margin-bottom: 2rem;
  ${({ align }) => align && `text-align: ${align}`};
  @media (max-width: 1024px) {
    font-size: 3.4rem;
    ${({ talign }) => talign && `text-align: ${talign}`};
  }
  @media (max-width: 767px) {
    font-size: 3.4rem;
    ${({ malign }) => malign && `text-align: ${malign}`};
  }
`

export const H5 = styled.h5`
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: ${({ color }) => (color ? color : colors.jetblack)};
  font-size: 2.4rem;
  line-height: 1.4;
  margin-bottom: 1rem;
  ${({ align }) => align && `text-align: ${align}`};
  @media (max-width: 1024px) {
    margin-bottom: 1rem;
    ${({ talign }) => talign && `text-align: ${talign}`};
  }
  @media (max-width: 767px) {
    margin-bottom: 1rem;
    ${({ malign }) => malign && `text-align: ${malign}`};
  }
`

export const P = styled.p`
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: ${({ color }) => (color ? color : colors.brownishgrey)};
  font-size: 1.8rem;
  line-height: 2.8rem;
  ${({ align }) => align && `text-align: ${align}`};
  margin-bottom: 4rem;
  margin-right: 0;
  @media (max-width: 1024px) {
    font-size: 1.8rem;
    ${({ talign }) => talign && `text-align: ${talign}`};
  }
  @media (max-width: 767px) {
    font-size: 1.8rem;
    ${({ malign }) => malign && `text-align: ${malign}`};
  }
  ${({ hero }) =>
    hero &&
    `
    margin: 0 auto;
  `}
  @media (min-width: 325px) {
    max-width: 100%;
  }
  @media (min-width: 767px) {
    max-width: 620px;
  }
  @media (min-width: 1025px) {
    max-width: 1180px;
  }
`

export const StyledSection = styled.div`
  ${({ size }) => {
    return `
        padding-top: ${size}rem;
        padding-bottom: ${size}rem;
        @media (max-width: 1024px) {
          padding-top: ${size}rem;
          padding-bottom: ${size}rem;
        }
        @media (max-width: 767px) {
          padding-top: ${size / 2}rem;
          padding-bottom: ${size / 2}rem;
        }
        `
  }}
`

export const Spacer = styled.div`
  ${({ height }) => (!height ? `height: 0` : `height: ${height}px`)};
  @media (max-width: 1920px) {
    ${({ xlHeight }) => (!xlHeight ? `height: 0` : `height: ${xlHeight}px`)};
  }
  @media (max-width: 1366px) {
    ${({ lHeight }) => (!lHeight ? `height: 0` : `height: ${lHeight}px`)};
  }
  @media (max-width: 1199px) {
    ${({ tHeight }) => (!tHeight ? `height: 0` : `height: ${tHeight}px`)};
  }
  @media (max-width: 767px) {
    ${({ mHeight }) => (!mHeight ? `height: 0` : `height: ${mHeight}px`)};
  }
`
// typography elements end

export const Trigger = styled.div`
  width: 100vw;
  height: 1px;
  position: absolute;
  top: ${({ top }) => (top ? top : 50)}vh;
`

export const MoreLink = styled(Link)`
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  display: block;
  font-size: 1.8rem;
  line-height: 2.8rem;
  cursor: pointer;
  &::after {
    display: inline-block;
    position: relative;
    left: 1rem;
    font-size: 1em;
    content: "→";
    transition: left 0.3s;
  }
  color: ${colors.jetblack};
  &:not([href]) {
    color: ${colors.jetblack};
  }
  cursor: pointer;
  &:hover {
    color: ${colors.jetblack};
    &::after {
      left: 1.5rem;
    }
  }
  ${({ align }) => align && `text-align: ${align}`};
  @media (max-width: 1024px) {
    ${({ talign }) => talign && `text-align: ${talign}`};
  }
  @media (max-width: 767px) {
    ${({ malign }) => malign && `text-align: ${malign}`};
  }
  ${({ simple }) => simple && `&::after {display: none;}`}
`

export const BackButton = styled.div`
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  display: block;
  font-size: 1.8rem;
  line-height: 2.8rem;
  cursor: pointer;
  padding-left: 1rem;
  &::before {
    display: inline-block;
    position: relative;
    right: 1rem;
    font-size: 1em;
    content: "←";
    transition: right 0.3s;
  }
  color: ${colors.jetblack};
  &:not([href]) {
    color: ${colors.jetblack};
  }
  cursor: pointer;
  &:hover {
    color: ${colors.jetblack};
    &::after {
      left: 1.5rem;
    }
  }
  ${({ align }) => align && `text-align: ${align}`};
  @media (max-width: 1024px) {
    ${({ talign }) => talign && `text-align: ${talign}`};
  }
  @media (max-width: 767px) {
    ${({ malign }) => malign && `text-align: ${malign}`};
  }
`

export const A = styled.a`
  display: block;
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: ${colors.jetblack};
  &:hover {
    color: ${colors.jetblack};
  }
`

export const MenuLink = styled(Link)``

export const Button = styled(Link)`
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  line-height: 2.8rem;
  font-family: "Poppins", sans-serif;
  text-align: center;
  color: ${colors.jetblack};
  min-width: 28rem;
  padding: 1.1rem 2rem;
  border-radius: 4rem;
  border: 1px solid ${colors.jetblack};
  &:hover {
    color: ${colors.jetblack};
    text-decoration: none;
  }
  ${({ yellow }) =>
    yellow &&
    `
    background-color: ${colors.mikadoyellow};
    color: ${colors.jetblack};
    border: 1px solid ${colors.mikadoyellow};
  `}
  @media (max-width: 767px) {
    width: 100%;
  }
`

export const FilterButton = styled.div`
  cursor: pointer;
  margin-top: 2rem;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  line-height: 2.8rem;
  font-family: "Poppins", sans-serif;
  text-align: center;
  color: ${colors.jetblack};
  padding: 1.1rem 2rem;
  border-radius: 4rem;
  border: 1px solid ${colors.jetblack};
  &:hover {
    color: ${colors.jetblack};
  }
`

export const fade = keyframes`
  0%  {opacity: 0;}
  100%  {opacity: 1;}
`
export const DecorationLines = styled.img`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 8;
  ${({ position }) => (position === "top" ? "top: -8rem" : "bottom: -8rem")};
  ${({ noPosition }) =>
    noPosition &&
    `
    position: static;
    width: 100%;
  `};
  @media (max-width: 1024px) {
    display: none !important;
  }
`

export const Loader = styled.div`
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
  ${({ menuStatus }) =>
    menuStatus &&
    `
    background-color: #ffc806;`}
  ${({ active }) =>
    !active &&
    css`
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 0.5s, opacity 0.5s linear;
    `}
`

export const Preloader = styled.div`
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 998;
  width: 100%;
  height: 100%;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;

  visibility: visible;
  opacity: 1;
  animation: ${fade} 0.3s linear;
`

export const DropsContainer = styled.div`
  position: fixed;
  top: calc(50vh - 13px);
  left: calc(50% - 72px);
  padding-left: 19px;
  width: 143px;
  height: 26px;
  overflow: hidden;
  color: white;
  visibility: visible;
  opacity: 1;
  animation: ${fade} 0.5s cubic-bezier(0.86, 0.01, 0.77, 0.78);
  ${({ active }) =>
    !active &&
    css`
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 0.3s, opacity 0.3s linear;
    `}
`

function move(props) {
  return keyframes`
    from {top:0px ;}
    to {top: calc(207px - ${props.mh});}
  `
}

export const Drop = styled.div`
  width: 32px;
  top: 0px;
  left: 0;
  opacity: 0;
  background-color: #ffc806;
  border-radius: 25px;
  animation: ${fade} 1s infinite, ${props => move} 1s infinite;
  position: absolute;
  animation-timing-function: linear;
  ${({ mh }) => mh && `min-height: ${mh}`};
  ${({ lf }) => lf && `left: ${lf}`};
  ${({ delay }) => delay && `animation-delay: ${delay}`};
  ${({ menuStatus }) => menuStatus && `background-color: white;`};
`

export const ELoader = styled.span`
  top: 0px;
  left: 0;
  opacity: 1;
  margin-right: 0;
  background-image: url();
  ${({ src }) => src && `background-image: url(${src})`};
  fill: ${colors.mikadoyellow};
  animation: ${fade} 1s;
  animation-iteration-count: 1;
  position: absolute;
  width: 18px;
  height: 18px;
  object-fit: contain;
`

export const LeseImageContainer = styled.div`
  width: 100%;
`

export const IndexPageSectionTitle = styled(H1)`
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  word-spacing: 10px;
`

export const IndexPageSectionTitleSecond = styled(H2)`
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  word-spacing: 10px;
`

export const SectionContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 5vw;
  margin-bottom: 10rem;
  ${({ bc }) => (!bc ? `background-color: white` : `background-color: ${bc}`)};
`
